/* AutocompleteStyles.css */

.aa-Autocomplete {
  position: relative;
  z-index: 1000;
}

.aa-Panel {
  background-color: white !important;
  border-radius: 4px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  margin-top: 8px !important;
  overflow: hidden;
  position: absolute;
  transition: opacity 200ms ease-in, filter 200ms ease-in !important;
  z-index: 1000 !important;
}

.aa-Item {
  align-items: center;
  background-color: white !important;
  border-radius: 3px !important;
  cursor: pointer;
  display: flex;
  min-height: 44px;
  padding: 10px;
  z-index: 1000 !important;
}

.aa-Item[aria-selected='true'] {
  background-color: rgba(179, 173, 214, 0.205) !important;
}

.aa-Item:hover {
  background-color: #f0f0f0 !important;
}

.aa-List {
  background-color: white !important;
  z-index: 1000 !important;
}
